import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogProps,
    DialogSurface,
    Field,
    Image,
    Input,
    RadioGroup,
    Textarea,
    makeStyles,
    shorthands,
} from '@fluentui/react-components';
import React, { useCallback, useContext, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ProjectContext, getNewProjectName, getSortedTemplates, useProjectTemplates } from '../../data';
import { MaxDescriptionLength, MaxTitleLength, Project } from '../../models';
import { useAppStyles } from '../../styles';
import { IntlProps, RadioCard, TrackContext } from '../common';
import { CancelButton, ConfirmButton, DialogHeader } from '../dialogs';

const useStyles = makeStyles({
    templateList: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        ...shorthands.gap('0.5rem'),
        marginBottom: '1rem',
    },
});

// Create project dialog.
export default injectIntl((props: IntlProps<Partial<DialogProps>>) => {
    const { intl } = props;
    const styles = useStyles();
    const appStyles = useAppStyles();

    const [projectStore, dispatch] = useContext(ProjectContext);
    const [title, setTitle] = useState(getNewProjectName(projectStore));
    const [description, setDescription] = useState('');

    const [templateStore] = useProjectTemplates();
    const templates = getSortedTemplates(templateStore);
    const [templateId, setTemplateId] = useState<string | undefined>();

    const onConfirm = useCallback(() => {
        const template = templates.find((o) => o.id === templateId)!;
        const desc = description.trim();
        dispatch({
            type: 'Create',
            template,
            project: {
                title: title.trim(),
                description: desc.length > 0 ? desc : undefined,
            } as Project,
        });
    }, [title, description, templates, templateId, dispatch]);

    return (
        <TrackContext.Provider value={{ container: 'create-project-dialog' }}>
            <Dialog {...props}>
                <DialogSurface className={appStyles.dialogNarrow}>
                    <DialogBody>
                        <DialogHeader
                            title={intl.formatMessage({
                                description: 'Create project dialog title.',
                                defaultMessage: 'Create a project',
                            })}
                        />
                        <DialogContent>
                            <Field
                                className={appStyles.dialogField}
                                required
                                label={intl.formatMessage({
                                    description: 'Label for the project title text box.',
                                    defaultMessage: 'Project title',
                                })}
                            >
                                <Input
                                    value={title}
                                    onChange={(_, { value }) => setTitle(value)}
                                    maxLength={MaxTitleLength}
                                    placeholder={intl.formatMessage({
                                        description: 'Placeholder text inside the project title text box.',
                                        defaultMessage: 'Name your project',
                                    })}
                                />
                            </Field>
                            <Field
                                className={appStyles.dialogField}
                                label={intl.formatMessage({
                                    description: 'Label for the project description text box.',
                                    defaultMessage: 'Project description',
                                })}
                            >
                                <Textarea
                                    value={description}
                                    onChange={(_, { value }) => setDescription(value)}
                                    maxLength={MaxDescriptionLength}
                                    placeholder={intl.formatMessage({
                                        description: 'Placeholder text inside the project description text box.',
                                        defaultMessage: 'Tell us about your project...',
                                    })}
                                />
                            </Field>
                            <Field
                                className={appStyles.dialogField}
                                required
                                label={intl.formatMessage({
                                    description: 'Label for template selection radio group.',
                                    defaultMessage: 'Select a template',
                                })}
                            >
                                <RadioGroup className={styles.templateList}>
                                    {templates.map((template) => (
                                        <RadioCard
                                            {...template}
                                            key={template.id}
                                            icon={<Image src={template.icon} />}
                                            selected={templateId === template.id}
                                            onSelect={() => setTemplateId(template.id)}
                                        />
                                    ))}
                                </RadioGroup>
                            </Field>
                        </DialogContent>
                        <DialogActions>
                            <CancelButton />
                            <ConfirmButton
                                appearance="primary"
                                disabled={!(title.trim() && templateId !== undefined)}
                                onClick={onConfirm}
                                trackProps={{ templateId }}
                            >
                                <FormattedMessage
                                    description="Create new project dialog confirm button."
                                    defaultMessage="Create project"
                                />
                            </ConfirmButton>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </TrackContext.Provider>
    );
});

import { Tab, TabList, makeStyles, mergeClasses } from '@fluentui/react-components';
import { isNonEmptyString } from '@microsoft/vscodeedu-api';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { MOBILE_AND_BELOW, useAppStyles } from '../../styles';

const useStyles = makeStyles({
    container: {
        position: 'relative',
        [MOBILE_AND_BELOW]: {
            display: 'none',
        },
    },
    navigator: {
        position: 'sticky',
        top: '1rem',
    },
    item: {
        display: 'flex',
        textAlign: 'left',
        minWidth: '15rem',
        maxWidth: '20rem',
        '& > .fui-Tab__content--reserved-space': {
            display: 'none',
        },
        '&[aria-selected="true"] > .fui-Tab__content': {
            fontWeight: 'unset',
            WebkitTextStrokeWidth: '0.05ex',
        },
    },
    item2: {
        '& > .fui-Tab__content': {
            paddingLeft: '1rem',
        },
    },
});

type NavItem = Readonly<{
    id: string;
    node: Element;
    title: string;
    className?: string;
}>;

// Page navigator properties.
export type PageNavigatorProps = Readonly<{
    containerId: string;
}>;

// Page navigator component.
export default (props: PageNavigatorProps) => {
    const { containerId } = props;
    const appStyles = useAppStyles();
    const styles = useStyles();
    const [items, setItems] = useState<NavItem[]>([]);
    const [selected, setSelected] = useState('');

    // Watch changes to header visibility.
    useEffect(() => {
        const observer = new IntersectionObserver(() => {
            const item = items.find((o) => o.node.getBoundingClientRect().bottom >= 0);
            if (item) {
                setSelected(item.id);
            }
        });
        items.forEach((o) => observer.observe(o.node));
        observer.takeRecords();
        return () => observer.disconnect();
    }, [items]);

    // Watch changes to the container element.
    useEffect(() => {
        const container = document.getElementById(containerId);
        if (container) {
            // Get all header elements under container element.
            const onChange = () => {
                const headers = Array.from(container.querySelectorAll('h1, h2'));
                if (headers.length < 2) {
                    // Don't show navigation for single or no headers.
                    setItems([]);
                } else {
                    // Set navigation items based on headers.
                    setItems(
                        headers
                            .filter((o) => isNonEmptyString(o.textContent))
                            .map((o, index) => ({
                                id: index.toString(),
                                node: o as HTMLElement,
                                title: o.textContent!,
                                className: o.tagName === 'H2' ? styles.item2 : undefined,
                            }))
                    );
                }
            };

            const observer = new MutationObserver(onChange);
            observer.observe(container, { childList: true, subtree: true });
            onChange();
            return () => observer.disconnect();
        } else {
            return undefined;
        }
    }, [containerId, styles.item2]);

    // Scroll header into view when an item is selected.
    const onTabSelect = useCallback(
        (tab: string) => {
            const item = items.find((o) => o.id === tab);
            if (item) {
                item.node.scrollIntoView({ behavior: 'smooth' });
                setSelected(tab);
            }
        },
        [items]
    );

    // Hide navigation when there are no items.
    if (!items.length) {
        return <></>;
    }

    return (
        <div className={styles.container}>
            <div className={mergeClasses(appStyles.flexColumnSmallGap, styles.navigator)}>
                <div className={appStyles.textSmall}>
                    <FormattedMessage description="Table of contents title." defaultMessage="On this page" />
                </div>
                <TabList
                    vertical
                    size="medium"
                    selectedValue={selected}
                    onTabSelect={(_, { value }) => onTabSelect(value as string)}
                >
                    {items.map((o) => (
                        <Tab key={o.id} value={o.id} className={mergeClasses(styles.item, o.className)}>
                            {o.title}
                        </Tab>
                    ))}
                </TabList>
            </div>
        </div>
    );
};

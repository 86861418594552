import { Body1, Body1Strong, Image, makeStyles } from '@fluentui/react-components';
import * as React from 'react';
import { injectIntl } from 'react-intl';
import { useConfig } from '../data';
import { useAppStyles } from '../styles';
import { getContentUrl } from '../utilities';
import { IntlProps } from './common';

const useStyles = makeStyles({
    authorPhoto: {
        width: '3rem',
        height: '3rem',
        marginRight: '1rem',
    },
});

// Course author information properties.
export type AuthorInfoProps = IntlProps<{
    photo?: string;
    displayName: string;
    affiliation?: string;
}>;

// Course author information panel.
export default injectIntl((props: AuthorInfoProps) => {
    const { photo, displayName, affiliation, intl } = props;
    const { contentBaseUrl } = useConfig();
    const appStyles = useAppStyles();
    const styles = useStyles();

    const imageSrc =
        photo !== undefined ? (photo.startsWith('$/') ? getContentUrl(contentBaseUrl, photo) : photo) : undefined;

    return (
        <div className={appStyles.flexRowCentered}>
            {imageSrc !== undefined && (
                <Image
                    className={styles.authorPhoto}
                    shape="circular"
                    src={imageSrc}
                    alt={intl.formatMessage(
                        {
                            description: 'Author profile photo alt text. {NAME} is the name of the author.',
                            defaultMessage: 'Picture of {NAME}',
                        },
                        { NAME: displayName }
                    )}
                />
            )}
            <div className={appStyles.flexColumn}>
                <Body1Strong>{displayName}</Body1Strong>
                <Body1>{affiliation}</Body1>
            </div>
        </div>
    );
});

import {
    Button,
    Menu,
    MenuDivider,
    MenuItem,
    MenuItemCheckbox,
    MenuList,
    MenuPopover,
    MenuProps,
    MenuTrigger,
    Toast,
    ToastTitle,
    useToastController,
} from '@fluentui/react-components';
import { ArrowBounceRegular, DeleteRegular, WindowDevToolsFilled } from '@fluentui/react-icons';
import React, { useCallback, useContext } from 'react';
import { ConfigContext, getConfig, isProduction } from '../../data';
import { Config } from '../../models';
import { useAppStyles } from '../../styles';

const options: { [key in keyof Partial<Config>]: string } = {
    cleverAuth: 'Enable Clever Auth',
    curriculumAuthoring: 'Enable curriculum authoring',
    themeMenu: 'Enable theme menu',
    highContrastThemes: 'Enable high contrast themes',
    ocvFeedback: 'Enable OCV feedback',
};

const optionsKeys = Object.keys(options) as (keyof Config)[];

// Developer tools menu props.
export type DevToolsMenuProps = Readonly<{
    asSubMenu?: boolean;
}>;

// Header developer tools menu.
// Not localized since this is an internal-only feature.
export default (props: DevToolsMenuProps) => {
    const { asSubMenu } = props;
    const [store, dispatch] = useContext(ConfigContext);
    const config = getConfig(store);
    const checkedValues = { features: optionsKeys.filter((key) => config[key]) };
    const { dispatchToast } = useToastController('app-toaster');
    const appStyles = useAppStyles();

    const onCheckedValueChange: MenuProps['onCheckedValueChange'] = (_, { checkedItems }) => {
        const config = Object.fromEntries(optionsKeys.map((key) => [key, checkedItems.includes(key)]));
        dispatch({ type: 'Update', config });
    };

    const onResetConfig = useCallback(() => {
        dispatch({ type: 'Reset' });
        dispatchToast(
            <Toast appearance="inverted">
                <ToastTitle>Config has been reset to current API environment defaults.</ToastTitle>
            </Toast>,
            { intent: 'success' }
        );
    }, [dispatch, dispatchToast]);

    const onClearLocalStorage = useCallback(() => {
        localStorage.clear();
        dispatch({ type: 'Reset' });
        dispatchToast(
            <Toast appearance="inverted">
                <ToastTitle>Deleted all data from the local storage.</ToastTitle>
            </Toast>,
            { intent: 'success' }
        );
    }, [dispatch, dispatchToast]);

    const onClearSessionStorage = useCallback(() => {
        sessionStorage.clear();
        dispatchToast(
            <Toast appearance="inverted">
                <ToastTitle>Deleted all data from the session storage.</ToastTitle>
            </Toast>,
            { intent: 'success' }
        );
    }, [dispatchToast]);

    return (
        <>
            {!isProduction && (
                <Menu checkedValues={checkedValues} onCheckedValueChange={onCheckedValueChange}>
                    <MenuTrigger>
                        {asSubMenu === true ? (
                            <MenuItem className={appStyles.menuListItem} title="Developer tools">
                                Developer tools
                            </MenuItem>
                        ) : (
                            <Button appearance="subtle" icon={<WindowDevToolsFilled />} title="Developer tools" />
                        )}
                    </MenuTrigger>
                    <MenuPopover className={appStyles.menuPopover}>
                        <MenuList>
                            {optionsKeys.map((key) => (
                                <span key={key}>
                                    <MenuItemCheckbox className={appStyles.menuListItem} name="features" value={key}>
                                        {options[key]!.replace('|', '')}
                                    </MenuItemCheckbox>
                                    {options[key]!.includes('|') && <MenuDivider />}
                                </span>
                            ))}
                            <MenuDivider />
                            <MenuItem
                                className={appStyles.menuListItem}
                                icon={<ArrowBounceRegular />}
                                onClick={onResetConfig}
                            >
                                Reset config to environment
                            </MenuItem>
                            <MenuItem
                                className={appStyles.menuListItem}
                                icon={<DeleteRegular />}
                                onClick={onClearLocalStorage}
                            >
                                Clear local storage
                            </MenuItem>
                            <MenuItem
                                className={appStyles.menuListItem}
                                icon={<DeleteRegular />}
                                onClick={onClearSessionStorage}
                            >
                                Clear session storage
                            </MenuItem>
                        </MenuList>
                    </MenuPopover>
                </Menu>
            )}
        </>
    );
};
